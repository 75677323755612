import {CookieHelper, HashHelper, RequestHelper, StringHelper} from '../helpers';
import { VEHICLE_TYPE } from '../const';

export default {
    checkSegmentLoaded() {
        return new Promise((resolve, reject) => {
			setTimeout(()=>{
				if(segmentCheckingInterval) {
					clearInterval(segmentCheckingInterval);
					window.segmentLoaded = false;
                    reject(false);
				}
			}, 60000);
            var segmentCheckingInterval = setInterval(() => {
                if ( window.analytics ) {
                    clearInterval(segmentCheckingInterval);
                    segmentCheckingInterval = null;
                    window.segmentLoaded = true;
                    resolve(true);
                }
            }, 500);
          });
    },
    loadSegment(isProduction) {
        if (!isProduction) {
            window.analytics.load('lpoFRMifdbFdurOPEBJjD5bTbJpgttW3', {
                integrations: {
                    'All': true,
                    'Braze': true,
                    'Braze Cloud Mode (Actions)': true,
                    'Braze Web Device Mode (Actions)': true,
                    'Google Analytics 4 Cloud': true,
                    'Google Analytics 4 Web': true,
                    'Webhooks': true,
                    'Criteo': true,
                    'Bing Ads': true,
                    'CM360 Offline Conv - VBB - Appr - Dealt (Personas - Prod)': true,
                    'CM360 Offline Conv - VBB - Est. Rev (Personas - Prod)': true,
                    'CM360 Offline Conversions - v2 - Prod (Personas - Prod)': true,
                    'Facebook Conversions API (Actions)': true,
                    'Facebook Pixel': true,
                    'Google Ads Conversions': true,
                    'Google Ads Remarketing Lists': true,
                    'Google AdWords New': true,
                    'Lead Broker Declined list (Personas - Prod)': true,
                    'Personas Facebook Custom Audiences': true,
                    'TikTok Conversions': true
                }
            });
        }
    },
    trackApplicationFormStart(state, obj) {
        if (window.analytics) {
            let trackData = {
                "loan_submission_guid": obj.guid
            };

            trackData = {};

            trackData.vehicle_type = obj.vehicleType ? obj.vehicleType.toLowerCase() : "car";

            const cookieString = document.cookie;
            const context = {
                consent: {
                    categoryPreferences: {
                        analytics: CookieHelper.getCookieValue(cookieString, 'cookieyes-analytics') === 'yes' || false,
                        advertising: CookieHelper.getCookieValue(cookieString, 'cookieyes-advertisement') === 'yes' || false,
                        functional: CookieHelper.getCookieValue(cookieString, 'cookieyes-functional') === 'yes' || false,
                        performance: CookieHelper.getCookieValue(cookieString, 'cookieyes-performance') === 'yes' || false,
                        necessary: CookieHelper.getCookieValue(cookieString, 'cookieyes-performance') === 'yes' || true,
                    }
                }
            }

            let utmData = {};
    
            // Extract UTM parameters from state
            if (state && state.form && state.form.utmParameters) {
                utmData.gclid = state.form.utmParameters.gclid  || null;
                utmData.fbclid = state.form.utmParameters.fbclid || null;
            }

            // Extract additional parameters from query variables
            const queryParams = ['ttclid', 'msclkid', 'wbraid', 'gbraid', 'utm_id', 'fbclid'];
            queryParams.forEach(param => {
                const value = RequestHelper.getQueryVariable(param);
                utmData[param] = value || null;
            });

            if (state.form.utmParameters) {
                const utmFields = [
                    'campaign', 'content', 'medium', 'source', 'term', 'type',
                ];

                utmFields.forEach(field => {
                    const key = `utm_${field}`;
                    const value = state.form.utmParameters[field];
                    utmData[key] = value ? StringHelper.toLowerCaseAndTrim(value) : null;
                });
            }

            const identifyObj = {
                cust_last_campaign_content: utmData.utm_content || null,
                cust_last_campaign_medium: utmData.utm_medium || null,
                cust_last_campaign_name: utmData.utm_campaign || null,
                cust_last_campaign_source: utmData.utm_source || null,
                cust_last_campaign_term: utmData.utm_term || null,
                cust_last_campaign_id: utmData.utm_id || null,
                cust_last_campaign_type: utmData.utm_type || null,
                cust_last_campaign_fbclid: utmData.fbclid || null,
                cust_last_campaign_gbraid: utmData.gbraid || null,
                cust_last_campaign_gclid: utmData.gclid || null,
                cust_last_campaign_msclkid: utmData.msclkid || null,
                cust_last_campaign_ttclid: utmData.ttclid || null,
                cust_last_campaign_wbraid: utmData.wbraid || null,
            };

            // Extract UTM parameters from state
            if (state && state.form && state.form.utmParameters) {
                trackData.gclid = state.form.utmParameters.gclid || null;
                trackData.fbclid = state.form.utmParameters.fbclid || null;
            }

            queryParams.forEach(param => {
                const value = RequestHelper.getQueryVariable(param);
                trackData[param] = value || null;
            });

            if (state.form.utmParameters) {
                const utmFields = [
                    'campaign', 'content', 'medium', 'source', 'term', 'type',
                ];
            
                utmFields.forEach(field => {
                    const key = `utm_${field}`;
                    const value = state.form.utmParameters[field];
                    trackData[key] = value ? StringHelper.toLowerCaseAndTrim(value) : null;
                });
            }
             
            const anonymousId = CookieHelper.getCookieValue(cookieString, 'ajs_anonymous_id');
            window.analytics.page("application", "Application Form Started", trackData, context, { anonymousId });
            window.analytics.identify(identifyObj, context);
        }
    },
    trackPage(state, guid, pageName) {
        if (window.analytics && window.segmentLoaded ) {
            let eventName = "Application Page Change";

            let trackData = {
                "loan_submission_guid": guid
            };

            trackData.loan_application_step = pageName.toLowerCase();

            // Extract UTM parameters from state
            if (state && state.form && state.form.utmParameters) {
                trackData.gclid = state.form.utmParameters.gclid || null;
                trackData.fbclid = state.form.utmParameters.fbclid || null;
            }

            // Extract additional parameters from query variables
            const queryParams = ['ttclid', 'msclkid', 'wbraid', 'gbraid', 'utm_id', 'fbclid'];
            queryParams.forEach(param => {
                const value = RequestHelper.getQueryVariable(param);
                trackData[param] = value || null;
            });

            if (state.form.utmParameters) {
                const utmFields = [
                    'campaign', 'content', 'medium', 'source', 'term', 'type'
                ];
            
                utmFields.forEach(field => {
                    const key = `utm_${field}`;
                    const value = state.form.utmParameters[field];
                    trackData[key] = value ? StringHelper.toLowerCaseAndTrim(value) : null;
                });
            }
 
            const cookieString = document.cookie;
            const context = {
                consent: {
                    categoryPreferences: {
                        analytics: CookieHelper.getCookieValue(cookieString, 'cookieyes-analytics') === 'yes' || false,
                        advertising: CookieHelper.getCookieValue(cookieString, 'cookieyes-advertisement') === 'yes' || false,
                        functional: CookieHelper.getCookieValue(cookieString, 'cookieyes-functional') === 'yes' || false,
                        performance: CookieHelper.getCookieValue(cookieString, 'cookieyes-performance') === 'yes' || false,
                        necessary: CookieHelper.getCookieValue(cookieString, 'cookieyes-performance') === 'yes' || true,
                    }
                }
            }

            const anonymousId = CookieHelper.getCookieValue(cookieString, 'ajs_anonymous_id');
            const userId = CookieHelper.getCookieValue(cookieString, 'ajs_user_id');

            if (userId) {
                window.analytics.page("application", eventName, trackData, context, { anonymousId }, { userId });
            }
            else {
                window.analytics.page("application", eventName, trackData, context, { anonymousId });
            }
        }
    },
    trackPersonalDetailsCompletedEvent(state, obj) {
        const eventName = "Loan Application Submitted";
        const tracksData = {};
        const submission_hostname = state.subdomain ? StringHelper.toLowerCaseAndTrim(state.subdomain + ".carfinance247.co.uk") : StringHelper.toLowerCaseAndTrim(window.location.host);
        const email_address = StringHelper.toLowerCaseAndTrim(obj.email);
        const vehicle_type = Object.keys(VEHICLE_TYPE).find(key => VEHICLE_TYPE[key] === obj.vehicle_type_id);
    
        tracksData.email = email_address;
        tracksData.loan_submission_guid = obj.guid;

        if (!obj.phone_number_normalised.startsWith('+')) {
            obj.phone_number_normalised = '+' + obj.phone_number_normalised;
        }
        tracksData.phone_number_e164 = obj.phone_number_normalised;
        tracksData.submission_hostname = submission_hostname;
    
        let gclid = '';
    
        if (obj && obj.utm) {
            gclid = obj.utm.gclid;
        }
    
        tracksData.vehicle_type = vehicle_type ? StringHelper.toLowerCaseAndTrim(vehicle_type) : '';
        
        if (window.analytics) {
            const cookieString = document.cookie;
            const context = {
                consent: {
                    categoryPreferences: {
                        analytics: CookieHelper.getCookieValue(cookieString, 'cookieyes-analytics') === 'yes' || false,
                        advertising: CookieHelper.getCookieValue(cookieString, 'cookieyes-advertisement') === 'yes' || false,
                        functional: CookieHelper.getCookieValue(cookieString, 'cookieyes-functional') === 'yes' || false,
                        performance: CookieHelper.getCookieValue(cookieString, 'cookieyes-performance') === 'yes' || false,
                        necessary: CookieHelper.getCookieValue(cookieString, 'cookieyes-performance') === 'yes' || true,
                    }
                }
            }
    
            const id = email_address;
    
            HashHelper.sha256(id).then(hash_result => {
                tracksData.user_id = hash_result;
    
                let identifyObj = {
                    user_id: hash_result,
                    date_of_birth: obj.date_of_birth,
                    email_address: email_address,
                    email: email_address,
                    first_name: StringHelper.toLowerCaseAndTrim(obj.first_name),
                    last_name: StringHelper.toLowerCaseAndTrim(obj.last_name),
                    phone_number_normalised: obj.phone_number_normalised,
                    postcode: StringHelper.toLowerCaseAndTrimAndRemoveAllSpaces(obj.postcode),
                    submission_hostname: submission_hostname,
                    _gclid: gclid
                };
    
                let utmData = {};
    
                // Extract UTM parameters from state
                if (state && state.form && state.form.utmParameters) {
                    utmData.gclid = state.form.utmParameters.gclid;
                    utmData.fbclid = state.form.utmParameters.fbclid;
                }
    
                // Extract additional parameters from query variables
                const queryParams = ['ttclid', 'msclkid', 'wbraid', 'gbraid', 'utm_id', 'fbclid'];
                queryParams.forEach(param => {
                    const value = RequestHelper.getQueryVariable(param);
                    utmData[param] = value || null;
                });

                if (state.form.utmParameters) {
                    const utmFields = [
                        'campaign', 'content', 'medium', 'source', 'term', 'type'
                    ];

                    utmFields.forEach(field => {
                        const key = `utm_${field}`;
                        const value = state.form.utmParameters[field];
                        utmData[key] = value ? StringHelper.toLowerCaseAndTrim(value) : null;
                    });
                }

                if (!obj.phone_number_normalised.startsWith('+')) {
                    obj.phone_number_normalised = '+' + obj.phone_number_normalised;
                }
                

                identifyObj = {
                    ...identifyObj,
                    email: email_address,
                    phone: obj.phone_number,
                    phone_number_e164: obj.phone_number_normalised,
                    loan_application_status: 'submitted',
                    cust_last_campaign_content: utmData.utm_content || null,
                    cust_last_campaign_medium: utmData.utm_medium || null,
                    cust_last_campaign_name: utmData.utm_campaign || null,
                    cust_last_campaign_source: utmData.utm_source || null,
                    cust_last_campaign_term: utmData.utm_term || null,
                    cust_last_campaign_id: utmData.utm_id || null,
                    cust_last_campaign_type: utmData.utm_type || null,
                    cust_last_campaign_fbclid: utmData.fbclid || null,
                    cust_last_campaign_gbraid: utmData.gbraid || null,
                    cust_last_campaign_gclid: utmData.gclid || null,
                    cust_last_campaign_msclkid: utmData.msclkid || null,
                    cust_last_campaign_ttclid: utmData.ttclid || null,
                    cust_last_campaign_wbraid: utmData.wbraid || null,
                    cust_last_loan_application_step: 'contact-details-p2',
                    cust_last_loan_application_submission_hostname: submission_hostname,
                    cust_last_loan_application_loan_submission_guid: obj.guid || null,
                };

                const anonymousId = CookieHelper.getCookieValue(cookieString, 'ajs_anonymous_id');
                const userId = CookieHelper.getCookieValue(cookieString, 'ajs_user_id');
    
                if (userId) {
                    window.analytics.track(eventName, tracksData, context, { anonymousId }, { userId });
                    window.analytics.identify(hash_result, identifyObj, context, { anonymousId }, { userId });
                }
                else {
                    window.analytics.track(eventName, tracksData, context, { anonymousId });
                    window.analytics.identify(hash_result, identifyObj, context, { anonymousId });
                }
            });
        }
    
        if (window.dataLayer) {
            let gaTrackData = Object.assign({}, tracksData);
    
            gaTrackData.category = "application";
            gaTrackData.event = eventName;
    
            window.dataLayer.push(gaTrackData);
        }
    }
}