<template>
  <form id="Form" @submit.prevent="Submit({ name: 'driving-licence' })">

    <input type="hidden" name="dummy" value="something">
    <InputGroup
        id="vehicle-type"
        name="form.vehicleTypeId"
        :label="getLabel"
        :items="[
        {label: 'Car', value: 40, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Car') },
        {label: 'Van', value: 41, canCoutinue: canCoutinue(), id: idConcat(pageName, 'van') },
        {label: 'Bike', value: 42, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Bike') }
      ]"
        v-model="$v.vehicleType.$model"
        :v="$v.vehicleType"
        v-show="showVehicleType"
        :submitFunction="continueBtnClick"
    />

    <ButtonContinue :id="idConcat(pageName, 'ContinueBtn')" :visible="visibleCoutinueButton()" :sub-label="$t('page.finance_details.button_continue.sub_label')">{{ isClassifyPartner ? 'Continue' : $t('page.finance_details.button_continue.inner_text') }}</ButtonContinue>
    <div style="font-size: 0.9em; text-align: center;" v-html="$t('page.finance_details.message.label')"></div>
    <div v-show="isGreenTickTest" class="green-tick">
      <div>
        <img src="@/assets/img/checked_1.png" width="20" class="checked" />
      </div>
      <div>
        Welcome to Car Finance 247, explore your options in <strong>just a few clicks</strong>
      </div>
    </div>
    <PrivacyBanner :id="idConcat(pageName, 'PrivacyBannerHref')" :label="isAffiliate && !isCarcraft ? 'By starting your quote you\'re agreeing to Car Finance 247\'s' : $t('page.finance_details.privacy_banner.label')" :is-bottom-fix="isGreenTickTest" />
    <section v-if="showPrivacyBannerQuote">
      <div style="padding: 0px;border-radius: 5px;font-size: .9em;text-align: center;margin-top: 20px;margin-bottom: 20px;">
        <span>Rates from 9.9% APR. Representative APR 19.9% <br />Car Finance 247 is a credit broker, not a lender. </span>
      </div>
    </section>
    <div style="font-size: 0.9em; text-align: center;" v-html="$t('page.finance_details.trade.label')"></div>
    <PowerByCf247Text />
    <RepExample></RepExample>
    <DisclosureStatement/>

    <div v-if="showNoImpactOnCreditScore" class="app-duration">
        <div>
            <img src="@/assets/img/checked_1.png" width="20" class="checked" />
        </div>
        <div>
          No impact on your credit score - see if you’ll be accepted before choosing to proceed.
        </div>
    </div>
    <div v-show="showAppDuration" class="app-duration">
        <div>
            <img src="@/assets/img/checked_1.png" width="20" class="checked" />
        </div>
        <div>
            Your no-obligation quote is just 1 minute away!
        </div>
    </div>


    <InfoFooter v-if="infoFooterKey == 'infoFooterKeyDone'" :showTopOnly="!isStartPage" :isStartPage="isStartPage" />
  </form>

</template>

<script>
import {required} from 'vuelidate/lib/validators'
import RepExample from "@/components/RepExample";
import DisclosureStatement from '@/components/DisclosureStatement';
import PowerByCf247Text from '@/components/PowerByCf247Text';
import {AffiliatesHelper, LocationHelper, RequestHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';


export default {
  components: {
    DisclosureStatement,
    RepExample,
    PowerByCf247Text,
  },
  data() {
    return {
      pageName: 'FinanceDetails',
      isStartPage: false,
      vehicleType: this.$store.state.form.vehicleTypeId,
      loanAmount: this.$store.state.form.loanAmount,
      loanAmountUnknown: this.$store.state.loanAmountUnknown,
      hasMonthlyBudget: this.$route.query.monthlyBudget,
      showLoanAmount: this.$store.state.showLoanAmount,
      isMonthlyBudgetAllowed: this.$store.state.isMonthlyBudgetAllowed,
      showVehicleType: true,
      isCarcraft: LocationHelper.getHost().startsWith('carcraft'),
      infoFooterKey: 'infoFooterKeyId'
    }
  },
  validations: {
    vehicleType: {
      required
    }
  },
  methods: {
    continueBtnClick: function() {
      setTimeout(()=> {
        this.Submit({ name: 'driving-licence' })
      }, 100);
    }
  },
  computed: {
    showPrivacyBannerQuote() {
      const host = [
        'pistonheads',
      ]
      return host.includes(LocationHelper.getHost());
    },
    isAffiliate() {
      return AffiliatesHelper.isAffiliate(AffiliatesHelper.getAffiliateWithLanguageFile()) || false;
    },
    isGreenTickTest() {
      return RequestHelper.getQueryVariable('test') === 'green-tick';
    },
    showAppDuration() {
      let appDuration = true;

      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined) {
        appDuration = false;
      }

      return appDuration && !this.$store.state.isDealer;
    },
    isClassifyPartner() {
      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined){
        const affiliateConfig = AFFILIATES[host];

        if (affiliateConfig.classifiedPartner && affiliateConfig.showPartnerModal) {
          return true;
        }
      }

      return false;
    },
    isNonAffiliatePersonalised() {
      let host = LocationHelper.getHost();
      return RequestHelper.getQueryVariable('personalised') && AFFILIATES[host] === undefined;
    },
    getLabel() {
      let label = this.$t('page.finance_details.input_group.vehicle_type.label');

      if (this.isNonAffiliatePersonalised) {
        const name = this.$store.getters.any('form.firstName');
        label = `Thanks ${name}, ${label}`;
      }

      return label;
    },
      showNoImpactOnCreditScore() {
      const host = ['social','ecrm', 'tiktok', 'social-premier', 'social-vans', 'social-bad-credit', 'apply', 'localhost:8080'];

      return host.indexOf(LocationHelper.getHost()) !== -1 && !this.$store.state.isDealer;
    }
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    let startPage;

    let vehicle_type = RequestHelper.getQueryVariable('vehicle_type');

    if (vehicle_type) {
      startPage = 'driving-licence'
    }

    let host = LocationHelper.getHost();

    if (AFFILIATES[host] !== undefined) {
      const affiliateConfig = AFFILIATES[host];


      if (affiliateConfig) {
       
        this.isStartPage = true;

        let vehicleid = RequestHelper.getQueryVariable('vehicleid');
        let vrm = RequestHelper.getQueryVariable('vrm');
        let advertId = RequestHelper.getQueryVariable('advertId');
        let vehicleType = RequestHelper.getQueryVariable('vehicle_type');

        if (vehicleid || vrm || advertId || vehicleType || host === 'comparethemarketcarfinance') {
          this.isStartPage = false;
          startPage = 'driving-licence';

          if (host === 'comparethemarketcarfinance') {
            startPage = 'submission-type';
          }

          this.vehicleType = this.$store.state.form.vehicleTypeId = 40;
        }
      }


      if (affiliateConfig && typeof affiliateConfig.showVehicleType !== "undefined") {
        this.showVehicleType = affiliateConfig.showVehicleType;
      }

      if (affiliateConfig && typeof affiliateConfig.startPage !== "undefined") {
        startPage = affiliateConfig.startPage;
      }
    }

    if (startPage) {
      this.Submit({ name: startPage });
    }

    this.infoFooterKey = 'infoFooterKeyDone';
  }
}
</script>

<style lang="scss" scoped>
  .app-duration {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      div {
        font-size: 1.15em;
        line-height: 30px;
      }
  }
  .checked {
      margin-right: 15px;
  }
  .green-tick {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    div {
      position: relative;
      top: -20px;
      left: 10px;
      font-size: 18px;
      line-height: 20px;

      @media (min-width: 1024px) {
        position: relative;
        top: 0px;
        left: 0px;
        font-size: 18px;
      }
    }
  }
</style>